import { FormValues } from "./types";
import downloadCode from "../../lib/downloadCode";
import { generateBootstrapTabsHTML } from "./funcs";
import { Button } from "@mui/material";
import { Download } from "@mui/icons-material";

interface Props {
  formValues: FormValues;
}

const HTMLSectionsExportToBootstrapTabs: React.FC<Props> = ({ formValues }) => {
  const bootstrapPreviewHTML = generateBootstrapTabsHTML({ formValues });
  // const [bootstrapPreview, setBootstrapPreview] = useState("");

  // const handleBootstrapPreview = useCallback(() => {
  //   const code = generateBootstrapCode();
  //   setBootstrapPreview(code);
  // }, [generateBootstrapCode]);

  // useEffect(() => {
  //   handleBootstrapPreview();
  // }, [handleBootstrapPreview]);

  const handleDownload = () => {
    const filename =
      (formValues.title || "untitled-html-sections") +
      ".bootstraptabbed.htmlsections.html";
    downloadCode(bootstrapPreviewHTML, filename);
  };

  return (
    <div className="HTMLSectionsExportToBootstrapTabs">
      <Button variant="contained" onClick={handleDownload}>
        <Download /> Download HTML
      </Button>
      <br />
      <br />
      <h4>HTML Preview:</h4>
      <div dangerouslySetInnerHTML={{ __html: bootstrapPreviewHTML }} />
      {/* <button onClick={handleBootstrapPreview}>Preview Bootstrap Tabs</button> */}
    </div>
  );
};

export default HTMLSectionsExportToBootstrapTabs;
