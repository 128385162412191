import { useForm, useFieldArray, Controller } from "react-hook-form";
import "react-quill/dist/quill.snow.css";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { FormValues } from "./types";
import { useCallback, useEffect } from "react";
import { SetVal } from "../../types/general.types";
import ReactTinyMCE from "../../shared/ReactTinyMCE";

const HTMLSectionsForm = ({
  onFormChange,
  defaultValues,
}: // currentValues,
{
  onFormChange: SetVal<FormValues>;
  defaultValues: FormValues;
  // currentValues: FormValues;
}) => {
  const { control, register, handleSubmit, watch } = useForm<FormValues>({
    defaultValues,
  });

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "items",
  });

  const onSubmit = (data: FormValues) => {
    console.log(data);
  };

  const moveItem = (index: number, direction: "up" | "down") => {
    if (direction === "up" && index > 0) {
      move(index, index - 1);
    } else if (direction === "down" && index < fields.length - 1) {
      move(index, index + 1);
    }
  };

  const addSection = useCallback(() => {
    append({ title: "", contentHtml: "" });
  }, [append]);

  const watchedFields = watch(); // You can specify fields, e.g., watch('fieldName')

  // sync form data with parent component
  useEffect(() => {
    onFormChange(watchedFields);
  }, [watchedFields, onFormChange]);

  // add a default section if none exist
  useEffect(() => {
    if (!watchedFields.items.length) {
      addSection();
    }
  }, [addSection, watchedFields.items.length]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{ "& > :not(style)": { m: 1 } }}
    >
      <TextField
        label="Title"
        {...register("title")}
        fullWidth
        inputProps={{ style: { fontSize: "2rem" } }}
      />
      {fields.map((section, index) => (
        <Box
          key={section.id}
          sx={{
            marginBottom: "10px",
            border: "1px solid lightgrey",
            padding: "10px",
          }}
        >
          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            {...register(`items.${index}.title`)}
          />
          <Controller
            name={`items.${index}.contentHtml`}
            control={control}
            render={({ field }) => (
              <ReactTinyMCE
                {...field}
                // value={field.value}
                // onChange={(newValue: string) => field.onChange(newValue)}
              />
            )}
          />
          <Box>
            <IconButton
              onClick={() => moveItem(index, "up")}
              aria-label="move up"
            >
              <ArrowUpwardIcon />
            </IconButton>
            <IconButton
              onClick={() => moveItem(index, "down")}
              aria-label="move down"
            >
              <ArrowDownwardIcon />
            </IconButton>
            <IconButton onClick={() => remove(index)} aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      ))}
      <Button
        startIcon={<AddCircleOutlineIcon />}
        onClick={addSection}
        variant="contained"
      >
        Add HTML Section
      </Button>
    </Box>
  );
};
HTMLSectionsForm.whyDidYouRender = true;

export default HTMLSectionsForm;
