const downloadCode = (code: string, filename: string) => {
  const element = document.createElement("a");
  const file = new Blob([code], { type: "text/plain" });
  element.href = URL.createObjectURL(file);
  element.download = filename.replace(/ /g, "-");
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};

export default downloadCode;
