import { FormValues } from "./types";

export const generateBootstrapTabsHTML = ({
  formValues,
}: {
  formValues: FormValues;
}) => {
  const tabTitles = formValues.items
    .map(
      (section, index) =>
        `<li class="nav-item" role="presentation">
      <button class="nav-link ${
        index === 0 ? "active" : ""
      }" id="tab-${index}" data-bs-toggle="tab" data-bs-target="#content-${index}" type="button" role="tab" aria-controls="content-${index}" aria-selected="${
          index === 0 ? "true" : "false"
        }">${section.title}</button>
    </li>`
    )
    .join("");

  const tabContent = formValues.items
    .map(
      (section, index) =>
        `<div class="tab-pane fade ${
          index === 0 ? "show active" : ""
        }" id="content-${index}" role="tabpanel" aria-labelledby="tab-${index}">
      ${section.contentHtml}
    </div>`
    )
    .join("");

  return `
    <div class="auto-tabs">
      <ul class="nav nav-tabs" role="tablist">
        ${tabTitles}
      </ul>
      <div class="tab-content mt-3">
        ${tabContent}
      </div>
    </div>
  `;
};
