import { useCallback, useState } from "react";
import HTMLSectionsForm from "./form";
import { FormValues } from "./types";
import { SetVal } from "../../types/general.types";
import ExportAccordion from "./export";
import { Container } from "@mui/material";

const defaultValues: FormValues = { items: [], title: "" };

const HTMLSectionsBuilder = () => {
  const [formValues, setFormValues] = useState<FormValues>(defaultValues);

  // const handleFormSubmit = (data: FormValues) => {
  //   setFormValues(data);
  //   console.log("Updated data in ParentComponent:", data);
  // };

  const handleFormChange: SetVal<FormValues> = useCallback((updatedData) => {
    setFormValues(updatedData);
    console.log("Updated form data:", updatedData);
  }, []);

  return (
    <Container>
      <HTMLSectionsForm
        onFormChange={handleFormChange}
        defaultValues={defaultValues}
        // currentValues={formValues}
      />
      <ExportAccordion formValues={formValues} />
    </Container>
  );
};

export default HTMLSectionsBuilder;
