import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";

// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
// import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
// import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css';

interface ReactTinyMCEProps {
  value: string;
  onChange: (content: string) => void;
}

// The ref type is specified for useImperativeHandle
interface ReactTinyMCERef {
  getContent: () => string;
}

const ReactTinyMCE = forwardRef<ReactTinyMCERef, ReactTinyMCEProps>(
  ({ value, onChange }, ref) => {
    const editorRef = useRef<TinyMCEEditor | null>(null);

    useImperativeHandle(ref, () => ({
      getContent: () => {
        return editorRef.current ? editorRef.current.getContent() : "";
      },
    }));

    return (
      <Editor
        apiKey='d2r3iwf9ygbmss8xo6nmw02e53q5w59m0b0bbvnnzgywn18e'
        onInit={(evt, editor) => (editorRef.current = editor)}
        value={value}
        onEditorChange={(content, editor) => onChange(content)}
        initialValue='<p>This is the initial content of the editor.</p>'
        init={{
          height: 300,
          menubar: false,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | formatselect | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
    );
  }
);

export default ReactTinyMCE;
