// import logo from './logo.svg';
// import './App.css';

import HTMLSectionsBuilder from "./components/HTMLSectionsBuilder";

function App() {
  return (
    <div className="App">
      <HTMLSectionsBuilder />
    </div>
  );
}

export default App;
