import React, { useEffect, useState } from "react";
import { TextField, Button, Box } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";

const JsonDisplayWithCopy: React.FC<{ data: any }> = ({ data }) => {
  const [copied, setCopied] = useState(false);

  // reset copied after 2 seconds
  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => setCopied(false), 2000);
      return () => clearTimeout(timeout);
    }
  }, [copied]);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(JSON.stringify(data, null, 2))
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => console.error("Failed to copy text: ", err));
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" m={2}>
      <TextField
        multiline
        fullWidth
        rows={10}
        variant="outlined"
        value={JSON.stringify(data, null, 2)}
        InputProps={{
          readOnly: true,
        }}
      />
      <Button
        variant="contained"
        color="primary"
        startIcon={<ContentCopy />}
        onClick={handleCopy}
        sx={{ mt: 2 }}
      >
        {copied ? "Copied!" : "Copy JSON"}
      </Button>
    </Box>
  );
};

export default JsonDisplayWithCopy;
