import { FormValues } from "./types";
import downloadCode from "../../lib/downloadCode";
import { Button } from "@mui/material";
import { Download } from "@mui/icons-material";
import JsonDisplayWithCopy from "../lib/JSONDisplayWithCopy";

interface Props {
  formValues: FormValues;
}

const HTMLSectionsExportToJSON: React.FC<Props> = ({ formValues }) => {
  const handleDownload = () => {
    const filename =
      (formValues.title || "untitled-html-sections") + ".htmlsections.json";
    const json = JSON.stringify(formValues);

    downloadCode(json, filename);
  };

  return (
    <div className="HTMLSectionsExportToJSON">
      <Button variant="contained" onClick={handleDownload}>
        <Download /> Download JSON
      </Button>
      <br />
      <br />
      <JsonDisplayWithCopy data={formValues} />
    </div>
  );
};

export default HTMLSectionsExportToJSON;
