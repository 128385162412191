import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormValues } from "./types";
import HTMLSectionsExportToBootstrapTabs from "./export-bootstrap";
import HTMLSectionsExportToJSON from "./export-json";

interface ExportAccordionProps {
  formValues: FormValues;
}

const ExportAccordion: React.FC<ExportAccordionProps> = ({ formValues }) => {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Export to Bootstrap Tabs</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <HTMLSectionsExportToBootstrapTabs formValues={formValues} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Export to JSON</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <HTMLSectionsExportToJSON formValues={formValues} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ExportAccordion;
